html, body, #root, canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.controls {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: black;
  opacity: 0.5;
  color: white;
  padding: 5px;
  border-top-right-radius: 0.75em;
}

.learn-more {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: black;
  opacity: 0.5;
  color: white;
  padding: 5px;
  border-top-left-radius: 0.75em;
}

p {
  margin: 5px;
  font-family: monospace;
  font-size: 12px;
  letter-spacing: 0.75px;
}

a {
  color: white;
}